import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import config from '../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { handleApiError } from '../utils/errorHandler';
import { isAuthenticated } from '../utils/auth';
import {
  LoginSocialFacebook
} from 'reactjs-social-login';
import { useGoogleLogin } from '@react-oauth/google';
// 
import googleimage from "../images/google.png";
import facebookimage from "../images/facebook.png";
import SocialLinks from '../components/SocialLinks';

const REDIRECT_URI = "https://sandbox.limowide.com/signin/";
const SignIn = () => {

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
    if (isAuthenticated()) {
      navigate('/'); // Navigate to the homepage if the user is authenticated
    }
  }, [navigate])

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  console.log({ email, password })
  const handleEmail = (e) => {
    setEmail(e.target.value)
    setEmailError('');
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
    setPasswordError('');
  }
  const onLogoutSuccess = useCallback(() => {
    // setProfile(null);
    // setProvider("");
  }, []);
  const onLoginSuccessFacebook = useCallback(
    async ({ provider, data }) => {
      console.log(data);
      const payload = {
        provider: provider,
        access_token: data?.accessToken,
        first_name: data?.first_name,
        last_name: data?.last_name,
      };
      const configs = {
        method: "POST",
        url: `${config.apiSocialLogin}`,
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        }
      };
      const loginRes = await axios(configs).catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.data?.email || e?.response?.data?.data?.password)
      });
      if (loginRes?.data?.token?.access_token) {
        toast.success("Logged In Success");
        localStorage.setItem("userType", loginRes?.data?.type);
        localStorage.setItem("last_session", new Date().toISOString());
        localStorage.setItem("user", JSON.stringify(loginRes?.data));
        localStorage.setItem("is_guest", loginRes?.data?.is_guest);
        localStorage.setItem("token", JSON.stringify(loginRes?.data?.token?.access_token));
        const redirectPath = localStorage.getItem('redirectAfterLogin');
        localStorage.removeItem('redirectAfterLogin');
        const isGuest = loginRes?.data?.is_guest;
        // Navigate based on is_guest value
        if (isGuest) {
          navigate('/update-password');
        } else {
          redirectPath !== null ? navigate(redirectPath) : navigate('/profile');
        }
      }
    },
    []
  );
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => onLoginSuccess(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });
  const onLoginSuccess = (codeResponse) => {
    try {
      // Log the response received from Google
      console.log(codeResponse);

      // Fetch user info from Google API using the access token
      axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
          Authorization: `Bearer ${codeResponse.access_token}`,
          Accept: 'application/json'
        }
      })
        .then(response => {
          // Log the user info response
          console.log(response.data);
          const payload = {
            provider: "google",
            access_token: codeResponse.access_token,
            first_name: response.data.given_name, // Use response.data to access user info
            last_name: response.data.family_name
          };
          const configs = {
            method: "POST",
            url: `${config.apiSocialLogin}`,
            data: payload,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
            }
          };
          axios(configs).catch((e) => {
            toast.error(e?.response?.data?.data?.email);
            toast.error(e?.response?.data?.data?.password);
          }).then(response => {
            toast.success("Logged In Success");
            localStorage.setItem("userType", response?.data?.type);
            localStorage.setItem("last_session", new Date().toISOString());
            localStorage.setItem("user", JSON.stringify(response?.data));
            localStorage.setItem("is_guest", response?.data?.is_guest);
            localStorage.setItem("token", JSON.stringify(response?.data?.token?.access_token));
            const redirectPath = localStorage.getItem('redirectAfterLogin');
            localStorage.removeItem('redirectAfterLogin');
            const isGuest = response?.data?.is_guest;
            // Navigate based on is_guest value
            if (isGuest) {
              navigate('/update-password');
            } else {
              redirectPath !== null ? navigate(redirectPath) : navigate('/profile');
            }
          });
        })
        .catch(error => {
          // Handle user info fetch error
          console.error('Error fetching user info:', error);
          handleApiError(error);
          setLoading(false); // Reset loading state
        });
    } catch (error) {
      // Catch any unexpected errors
      console.error('Error exchanging code for access token:', error);
      handleApiError(error);
      setLoading(false); // Reset loading state
    }
  };


  const handleApi = () => {
    if (loading) return; // prevent function if already loading
    // Validation
    let isValid = true;
    if (!email.trim()) {
      setEmailError('Email is required');
      isValid = false;
    }
    if (!password.trim()) {
      setPasswordError('Password is required');
      isValid = false;
    }
    if (!isValid) return;
    setLoading(true); // set loading state to true
    console.log('API call initiated', { email, password });
    axios.post(`${config.apiLogin}`, {
      email: email,
      password: password
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
      }
    }).then(result => {
      toast.success("Logged In Success");
      localStorage.setItem("userType", result.data.type);
      localStorage.setItem("last_session", new Date().toISOString());
      localStorage.setItem("user", JSON.stringify(result.data));
      localStorage.setItem("token", JSON.stringify(result.data.token.access_token));
      const redirectPath = localStorage.getItem('redirectAfterLogin');
      localStorage.removeItem('redirectAfterLogin');
      // console.log(redirectPath);
      // redirectPath !== null ? navigate(redirectPath) : navigate('/');
      if (redirectPath !== null) {
          navigate(redirectPath);
      } else if (result.data.type === 'driver') {
          navigate('/dprofile');
      } else {
          navigate('/'); 
      } 
    })
      .catch(error => {
        toast.error("Invalid Login Credentials!");
      })
      .finally(() => {
        setLoading(false); // reset loading state
      });
  }
  return (
    <main className="container-fluid mt-5 pb-7 pt-lg-5 pt-4 bgimage">
      <Row className="justify-content-center gx-0">
        <Col lg={'8'} className="">
          <Card className="" style={{ border: 'none',backgroundColor:'transparent', }}>
            <Row className="g-0">
              <Col md={'6'} className="d-none d-md-block">
                <div className='h-100 login-card-before shadow-lg' style={{ borderRadius: '30px 0 0px 30px' }}>
                  <div className="h-100 w-100 login-card-bg p-4 overflow-hidden" style={{ borderRadius: '30px 0 0px 30px' }}>
                    <div className='welcometext'>
                      <h2 className='FontFamilyProstoOne'>Welcome Back!</h2>
                      <p className='text-white'>Your trusted partner for airport transfer</p>
                      <div className="d-flex alig-items-center justify-content-center gap-3">
                          <SocialLinks />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={'6'} className="login-card-after">
                <div className='px-5 py-4 h-100 w-100 overflow-hidden bg-white shadow-lg' style={{ borderRadius: '0px 30px 30px 0px' }}>
                  <h2 className="text-center mb-4" style={{ fontWeight: 'bold' }}>Sign In</h2>
                  <p className='text-center pragraphText' style={{ lineHeight: '20px' }}>Sign in for seamless access or create an account to unlock exclusive perks.</p>
                  <div className="loginform mt-5">
                    <div className="mb-3">
                      <Form.Control type="email" value={email} onChange={handleEmail} className="email-icon" placeholder="Email Address" required />
                      {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                    </div>
                    <div className="mb-3">
                      <Form.Control type="password" value={password} onChange={handlePassword} className="password-icon" placeholder="Password" required />
                      {passwordError && <span style={{ color: 'red' }}>{passwordError}</span>}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="form-check">
                        <Form.Control type="checkbox" className="form-check-input" style={{ height: '20px', padding: '10px' }} id="remember" />
                        <Form.Label className="form-check-label" htmlFor="remember">Remember Me</Form.Label>
                      </div>
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                    <Button variant={''} onClick={handleApi} disabled={loading} type="button" className="loginbtn w-100">
                      {loading ? <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div> : 'Log In'}
                    </Button>
                    <div className="text-center my-3 orlogin">
                      <span>or log in with</span>
                    </div>
                    <div className="d-flex gap-2 justify-content-center">
                      <Link to="#!" onClick={() => login()}><img src={googleimage} alt="" /></Link>
                      <LoginSocialFacebook
                        appId="1226842592047274"
                        fieldsProfile={
                          'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                        }
                        onLogoutSuccess={onLogoutSuccess}
                        redirect_uri={REDIRECT_URI}
                        onResolve={onLoginSuccessFacebook}
                        onReject={err => {
                          console.log(err);
                        }}
                      >
                        <Link to="#"> <img src={facebookimage} alt="" /> </Link>
                      </LoginSocialFacebook>
                    </div>
                    <p className="text-center mt-3 pragraphText">Don't have an account? <Link to="/register" className='fw-bolder blackText'>Register Now!</Link></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </main>
  );
};

export default SignIn;
