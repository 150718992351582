import React, { useState } from 'react';
import { Col, Card, NavItem, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';

// import images
import leftarrow from '../../images/leftarrow.svg';
const MyJob = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const clearOtp = () => {
        setOtp('')
    };
    const handleSubmit = () => {
        toast("Done!", { type: "info" })
        navigate("/at-destination");
    }
    return (
        <>
            <div style={{ backgroundColor: '#E5E5E5', minHeight: '100vh' }} className='w-100 d-flex justify-content-center align-items-center position-relative'>
                <section className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
                    <Col className='col-11 mx-auto'>
                        <form onSubmit={handleSubmit}>
                            <Card className='' style={{ backgroundColor: '#E5E5E5', border: 'none' }}>
                                <Card.Header className='mb-5' style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                                        <Link to="/customer-on-board-or-not"><img src={leftarrow} alt="" /></Link>
                                        <p className='m-0'><strong>Enter PIN No</strong></p>
                                    </div>
                                </Card.Header>
                                <Card.Body style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <p>Enter customer PIN to start the journey.</p>
                                    <div className="margin-top--small">
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderSeparator={<span> </span>}
                                            inputStyle="inputStyle"
                                            inputType="password"
                                            renderInput={(props) => <input {...props} />}
                                            shouldAutoFocus
                                        />
                                    </div>
                                </Card.Body>
                                <Card.Footer style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <div className="btn-row mt-5">
                                        {/* <button className="btn margin-top--large" type="button" disabled={otp.trim() === ''} onClick={clearOtp}>
                                        Clear
                                    </button> */}
                                        <Button type="submit" disabled={otp.length < 4} className="loginbtn w-100">
                                            {loading ? <div className="bouncing-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> : 'Continue'}
                                        </Button>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </form>
                    </Col>
                </section>
                {/*  */}
            </div>
        </>
    )
}

export default MyJob;