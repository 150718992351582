import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Loading from '../components/Loading';
import config from '../config';
import { handleApiError } from '../utils/errorHandler';
// import component
import BlogBoxUL from '../components/BlogBoxUL';
import FeaturedPostsUL from '../components/FeaturedPostsUL';
import LatestPostsUL from '../components/LatestPostsUL';
import CategoriesUL from '../components/CategoriesUL';
import PopularTagsUL from '../components/PopularTagsUL';


// CategoriesULArray
const CategoriesULArray = [
  {
    key: "0",
    title: "TRAVEL",
    number: "(08)",
  },
  {
    key: "1",
    title: "TRANSPORTATION",
    number: "(06)",
  },
]
// PopularTagsULArray
const PopularTagsULArray = [
  {
    key: "0",
    tagText: "Blogs",
  },
]

const Blogs = () => {
  const [isLoading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [latestBlogs, setLatestBlogs] = useState([]);
  useEffect(() => {
    setLoading(true);
    fetchblogs();
    fetchlatestblogs();
    window.scrollTo(0, 0)
  }, []);

  const fetchblogs = async () => {
    try {
      const result = await axios.get(`${config.Blogs}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
        }
      });
      if (result?.data) {
        setBlogs(result.data);
      } else {
        setBlogs([]);
      }
    } catch (error) {
      handleApiError(error);
      setBlogs([]);
    } finally {
      setLoading(false); // reset loading state
    }
  };
  const fetchlatestblogs = async () => {
    try {
      const result = await axios.get(`${config.BlogLatest}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
        }
      });
      if (result?.data) {
        setLatestBlogs(result.data);
      } else {
        setLatestBlogs([]);
      }
    } catch (error) {
      handleApiError(error);
      setLatestBlogs([]);
    } finally {
      setLoading(false); // reset loading state
    }
  };
  return (
    <>
      <section className="blogHead d-flex align-items-center justify-content-center">
        <Col xxl={'7'} xl={'7'} lg={'9'} md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1><span className=''>Inspiration</span> for travel with <span>Limowide</span></h1>
          <p className='mb-4 text-white'>Elevate your travel experiences and ignite your wanderlust with Limowide's inspiring blog content. </p>
        </Col>
      </section>
      {/*  */}
      {isLoading ? (
        <div className="flex justify-center items-center h-96">
          <Loading />
        </div>
      ) : (
        <>
          <section className='blogPageWork py-5'>
            <Col xxl={'9'} xl={'9'} lg={'10'} md={'10'} className='mx-auto col-11'>
              <Row className='pb-4'>
                <Col xxl={'8'} xl={'8'} lg={'7'} className='mb-4 mb-lg-0'>
                  <Row className={''}>
                    {isLoading ? (
                      <div className="flex justify-center items-center h-96">
                        <Loading />
                      </div>
                    ) : (
                      <>
                        {blogs.length > 0 ? (
                          blogs.map((blog, index) => {
                            return (
                              <BlogBoxUL
                                key={index}
                                counter={index}
                                blogMainImg={blog.thumbnail_image}
                                locationText={blog.category}
                                title={blog.blog_title}
                                paragraph={blog.content}
                                autherName={blog.category}
                                date={blog.created_at}
                                slug={blog.slug}
                              />
                            );
                          })
                        ) : (
                          <div className="flex justify-center items-center h-96">
                            <p className="text-lg font-bold ">No Record Found!</p>
                          </div>
                        )}
                      </>
                    )}
                  </Row>
                </Col>
                <Col xxl={'4'} xl={'4'} lg={'5'} className='mb-4 mb-lg-0'>
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Featured Posts</h4>
                  <div className='FeaturedPost'>
                    {latestBlogs.length > 0 ? (
                      latestBlogs.map((blog, index) => {
                        return (
                          <FeaturedPostsUL
                            key={index}
                            topText={blog.category}
                            title={blog.blog_title}
                            date={blog.created_at}
                            cardImg={blog.thumbnail_image}
                            slug={blog.slug}
                          />
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </div>
                  <hr />
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Categories</h4>
                  {CategoriesULArray.map((val) => {
                    return (
                      <CategoriesUL
                        key={val.key}
                        title={val.title}
                        number={val.number}
                      />
                    )
                  })}
                  <hr />
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Popular Tags</h4>
                  {PopularTagsULArray.map((val) => {
                    return (
                      <PopularTagsUL
                        key={val.key}
                        tagText={val.tagText}
                      />
                    )
                  })}
                </Col>
              </Row>
              {/*  */}
              <hr />
              <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Latest Posts</h4>
              <Row className='FeaturedPost'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {latestBlogs.length > 0 ? (
                      latestBlogs.map((blog, index) => {
                        return (
                          <LatestPostsUL
                            key={index}
                            topText={blog.category}
                            title={blog.blog_title}
                            date={blog.created_at}
                            cardImg={blog.thumbnail_image}
                            slug={blog.slug}
                          />
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </section>
        </>
      )}
    </>
  );
};

export default Blogs;
