import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/Loading';
import config from '../config';
import { handleApiError } from '../utils/errorHandler';
import sanitizeHtml from 'sanitize-html';
// import Img_1 from '../images/image3.jpg';
// import Img_2 from '../images/image4.jpg';
// import image11 from '../images/image11.jpg';
import Linkedin from '../images/Linkedin.png';
import Twitter from '../images/Twitter.png';
import Facebook1 from '../images/Facebook1.png';
import Pinterest from '../images/Pinterest.png';
import tagicon from '../images/tagicon.svg';
// import hearticon from '../images/hearticon.svg';
// import comentsicon from '../images/comentsicon.svg';
// import imageProfile from '../images/profileDP.jpg';

import FeaturedPostsUL from '../components/FeaturedPostsUL';
import CategoriesUL from '../components/CategoriesUL';
import PopularTagsUL from '../components/PopularTagsUL';
import { Helmet } from 'react-helmet';
// import CommentsUL from '../components/CommentsUL';



const Blogs = () => {
  const currentUrl = window.location.href;
  const sanitizeOptions = {
    allowedTags: [], // No tags allowed, only text content
    allowedAttributes: {} // No attributes allowed
  };
  const [isLoading, setLoading] = useState(false);
  const [blogImage, setBlogImage] = useState(null);
  const [blogTitle, setBlogTitle] = useState(null);
  const [blogMetatitle, setBlogMetatitle] = useState(null);
  const [blogkeyword, setBlogkeyword] = useState(null);
  const [blogdescription, setBlogdescription] = useState(null);
  const [blogContent, setBlogContent] = useState(null);
  const [BlogDate, setBlogDate] = useState(null);
  const [authur, setAuthur] = useState(null);
  const [tag, setTag] = useState(null);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [continents, setContinents] = useState([]);
  const [countries, setCountries] = useState([]);
  const [tags, setTags] = useState([]);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const { slug } = useParams();
  useEffect(() => {
    setLoading(true);
    fetchblog();
    fetchlatestblogs();
    window.scrollTo(0, 0)
  }, []);

  const fetchblog = async () => {
    try {
      const result = await axios.get(`${config.getBlogDetailUrl(slug)}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
        }
      });
      if (result?.data) {
        setBlogImage(result?.data?.blog_image);
        setBlogTitle(result?.data?.blog_title);
        setBlogContent(result?.data?.content);
        setTag(result?.data?.tags);
        setBlogDate(result?.data?.created_at);
        setAuthur(result?.data?.category);
        setCategories(result?.data?.category_count);
        setCities(result?.data?.city_count);
        setContinents(result?.data?.continent_count);
        setCountries(result?.data?.country_count);
        setTags(result?.data?.tags_count);
        setBlogMetatitle(result?.data?.meta_title);
        setBlogkeyword(result?.data?.meta_keywords);
        setBlogdescription(result?.data?.meta_description);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false); // reset loading state
    }
  };
  const fetchlatestblogs = async () => {
    try {
      const result = await axios.get(`${config.BlogLatest}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
        }
      });
      if (result?.data) {
        setLatestBlogs(result.data);
      } else {
        setLatestBlogs([]);
      }
    } catch (error) {
      handleApiError(error);
      setLatestBlogs([]);
    } finally {
      setLoading(false); // reset loading state
    }
  };
  return (
    <>
      <Helmet>
          <meta name="robots" content="index, follow" />
          <title>{blogMetatitle}</title>
          <link rel="canonical" href={currentUrl} />
          <meta name="title" content={blogMetatitle} />
          <meta name="description" content={blogdescription} />
          <meta name="keywords" content={blogkeyword}></meta>
          <meta property="og:title" content={blogMetatitle} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="WebPage" />
          <meta property="og:description" content={blogdescription} />
          <meta name="twitter:title" content={blogMetatitle} />
          <meta name="twitter:description" content={blogdescription} />
          <meta name="twitter:url" content={currentUrl} />
          <meta property="og:image" content={blogImage} />        
      </Helmet>
      <section className='py-4 color1BG'>
        <Col xxl={'10'} xl={'10'} className='col-11 mx-auto'>
          <img className='img-fluid w-100' src={blogImage} style={{ borderRadius: '30px 0 30px 0', height: '400px', objectFit: 'cover' }} alt='banner' />
        </Col>
      </section>
      {/*  */}
      {isLoading ? (
        <div className="flex justify-center items-center h-96">
          <Loading />
        </div>
      ) : (
        <>
          <section className='py-5 blogPageWork color1BG'>
            <Col xxl={'9'} xl={'9'} lg={'10'} md={'10'} className='mx-auto col-11'>
              <Row className='pb-4'>
                <Col xxl={'8'} xl={'8'} lg={'7'} className='mb-4 mb-lg-0'>
                  <h1 className='d-title mb-2'>{blogTitle}</h1>
                  <p className='mb-3' style={{ fontSize: '14px' }}>by <Link to='/' className="" style={{ fontWeight: 'bold' }}>{authur}</Link> {BlogDate}</p>
                  <div
                    dangerouslySetInnerHTML={{ __html: blogContent }}
                  />
                  {/* <p>One of the most iconic features of Bali is its sprawling rice terraces, which cascade down the island's hillsides in a patchwork of vibrant green hues. Ubud, located in the heart of Bali, is renowned for its terraced rice fields, where visitors can wander along meandering pathways and immerse themselves in the tranquility of rural life. Tegalalang Rice Terrace, with its dramatic terraced slopes, is a must-visit destination for nature enthusiasts and photographers alike.</p>
                  <p>Bali is also home to a myriad of breathtaking waterfalls, where visitors can escape the heat and refresh their spirits in the cool, clear waters. One such gem is the Tegenungan Waterfall, located just a short drive from Ubud. Surrounded by lush jungle foliage, this majestic cascade plunges into a pristine pool below, inviting travelers to take a refreshing dip amidst nature's beauty. In addition to its natural wonders, Bali boasts a rich cultural heritage that is evident in its traditional ceremonies, vibrant festivals, and ornate temples. The island's spiritual heart is palpable at sites such as the Tirta Empul Temple, where visitors can partake in a sacred purification ritual amidst ancient stone carvings and crystal-clear pools.</p>
                  <div className='d-flex mb-3 align-items-center' style={{ gap: '20px' }}>
                    <img className='img-fluid' src={Img_1} alt='banner' />
                    <img className='img-fluid' src={Img_2} alt='banner' />
                  </div> */}
                  {/* <p>Nestled amidst the verdant landscapes of Indonesia, Bali beckons travelers with promises of serenity and enchantment. With its lush rice terraces, cascading waterfalls, and tranquil temples, the island of Bali offers a haven for those seeking respite from the hustle and bustle of modern life.</p>
                  <p>After a day of exploration, travelers can unwind and indulge in Bali's world-class wellness offerings. From luxurious spa treatments to holistic healing practices, Bali is renowned for its wellness retreats that cater to mind, body, and soul. Whether practicing yoga amidst rice fields or enjoying a traditional Balinese massage overlooking the ocean, Bali offers endless opportunities for relaxation and rejuvenation.</p>
                  <p>In Bali, serenity is not just a state of mind—it's a way of life. With its idyllic rice terraces, mesmerizing waterfalls, and rich cultural heritage, Bali invites travelers to slow down, reconnect with nature, and discover the true meaning of bliss. Whether seeking adventure or seeking solace, Bali offers an unforgettable escape that nourishes the body, mind, and soul.</p> */}
                  <Row className='align-items-center mt-5'>
                    <Col sm={'6'}>
                      <div className='d-flex align-items-center justify-content-between' style={{ gap: '20px', }}>
                        <p className='m-0 fw-semibold'><img className='img-fluid me-2' src={tagicon} alt='icon' /> {tag}</p>
                        {/* <p className='m-0 fw-semibold'><img className='img-fluid me-2' src={hearticon} alt='icon' /> 24</p>
                        <p className='m-0 fw-semibold'><img className='img-fluid me-2' src={comentsicon} alt='icon' /> 03</p> */}
                      </div>
                    </Col>
                    <Col sm={'6'} className='text-sm-end text-start'>
                      <div className='d-flex align-items-center justify-content-end' style={{ gap: '20px', }}>
                        <Link to={'/'} className=''><img className='img-fluid' src={Linkedin} alt='icon' /></Link>
                        <Link to={'/'} className=''><img className='img-fluid' src={Twitter} alt='icon' /></Link>
                        <Link to={'/'} className=''><img className='img-fluid' src={Facebook1} alt='icon' /></Link>
                        <Link to={'/'} className=''><img className='img-fluid' src={Pinterest} alt='icon' /></Link>
                      </div>
                    </Col>
                  </Row>
                  {/* <hr />
                  <Row className='align-items-center'>
                    <Col lg={'6'}>
                      <div className='d-lg-flex align-items-center' style={{ gap: '20px' }}>
                        <img className={'img-fluid'} style={{ borderRadius: '8px' }} src={image11} alt='icon' />
                        <h4 className='m-0' style={{ fontSize: '16px', fontWeight: 'bold', }}>Skyward Journeys: Insider Tips for Booking Flights</h4>
                      </div>
                    </Col>
                    <Col lg={'6'}>
                      <div className='d-lg-flex align-items-center' style={{ gap: '20px' }}>
                        <img className={'img-fluid'} style={{ borderRadius: '8px' }} src={image11} alt='icon' />
                        <h4 className='m-0' style={{ fontSize: '16px', fontWeight: 'bold', }}>Skyward Journeys: Insider Tips for Booking Flights</h4>
                      </div>
                    </Col>
                  </Row>
                  <hr /> */}
                  {/*  */}
                  {/* <div className='py-4'>
                    <h6 className='allTile mb-4' style={{ textTransform: 'capitalize' }}>Comments</h6>
                    {CommentsULArray.map((val) => {
                      return (
                        <CommentsUL
                          key={val.key}
                          profileImg={val.profileImg}
                          title={val.title}
                          time={val.time}
                          paragraphText={val.paragraphText}
                        />
                      )
                    })}
                  </div> */}
                  {/*  */}
                  {/* <Form className={''}>
                    <div className='py-4'>
                      <h6 className='allTile' style={{ textTransform: 'capitalize' }}>Post a Comment</h6>
                      <Row className='mb-3'>
                        <Col lg={'6'} className=''><Form.Control className={'whiteBG'} style={{ height: '50px', border: '0', }} type='text' placeholder='Full Name' /></Col>
                        <Col lg={'6'} className=''><Form.Control className={'whiteBG'} style={{ height: '50px', border: '0', }} type='email' placeholder='Email' /></Col>
                      </Row>
                      <Form.Control className={'whiteBG mb-4'} placeholder={'Write a comment....'} as="textarea" rows={5} style={{ border: '0', }} />
                      <Button variant='' className='allView-btn'>Submit</Button>
                    </div>
                  </Form> */}
                </Col>
                <Col xxl={'4'} xl={'4'} lg={'5'} className='mb-4 mb-lg-0'>
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Featured Posts</h4>
                  <div className='FeaturedPost'>
                    {latestBlogs.length > 0 ? (
                      latestBlogs.map((blog, index) => {
                        return (
                          <FeaturedPostsUL
                            key={index}
                            topText={blog.category}
                            title={blog.blog_title}
                            date={blog.created_at}
                            cardImg={blog.thumbnail_image}
                            slug={blog.slug}
                          />
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </div>
                  <hr />
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>City</h4>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-96">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {cities.length > 0 ? (
                        cities.map((cat, index) => {
                          return (
                            <CategoriesUL
                              key={index}
                              title={cat.name}
                              number={cat.blog_count}
                            />
                          );
                        })
                      ) : (
                        <div className="flex justify-center items-center h-96">
                          <p className="text-lg font-bold ">No Record Found!</p>
                        </div>
                      )}
                    </>
                  )}
                  <hr />
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Continent</h4>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-96">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {continents.length > 0 ? (
                        continents.map((cat, index) => {
                          return (
                            <CategoriesUL
                              key={index}
                              title={cat.name}
                              number={cat.blog_count}
                            />
                          );
                        })
                      ) : (
                        <div className="flex justify-center items-center h-96">
                          <p className="text-lg font-bold ">No Record Found!</p>
                        </div>
                      )}
                    </>
                  )}
                  <hr />
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Country</h4>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-96">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {countries.length > 0 ? (
                        countries.map((cat, index) => {
                          return (
                            <CategoriesUL
                              key={index}
                              title={cat.name}
                              number={cat.blog_count}
                            />
                          );
                        })
                      ) : (
                        <div className="flex justify-center items-center h-96">
                          <p className="text-lg font-bold ">No Record Found!</p>
                        </div>
                      )}
                    </>
                  )}
                  <hr />
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Categories</h4>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-96">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {categories.length > 0 ? (
                        categories.map((cat, index) => {
                          return (
                            <CategoriesUL
                              key={index}
                              title={cat.name}
                              number={cat.blog_count}
                            />
                          );
                        })
                      ) : (
                        <div className="flex justify-center items-center h-96">
                          <p className="text-lg font-bold ">No Record Found!</p>
                        </div>
                      )}
                    </>
                  )}
                  <hr />
                  <h4 className='secondaryText mb-4' style={{ fontSize: '28px', fontWeight: 'bold', color: '' }}>Popular Tags</h4>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-96">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {tags.length > 0 ? (
                        tags.map((tag, index) => {
                          return (
                            <PopularTagsUL
                              key={index}
                              tagText={tag.name}
                            />
                          );
                        })
                      ) : (
                        <div className="flex justify-center items-center h-96">
                          <p className="text-lg font-bold ">No Record Found!</p>
                        </div>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </section>
        </>
      )}
    </>
  );
};

export default Blogs;
