import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Form, Row, Button, Dropdown } from 'react-bootstrap';
import { getStoredSearch } from '../utils/Search.jsx';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// import axios from "axios";
import leftright_img from '../images/leftright_img.svg';
import searchIcon from '../images/searchIcon.svg';
import airoplaneIcon from '../images/plane-svgrepo-com.svg';
import placeIcon from '../images/pin-svgrepo-com.svg';
import { toast } from 'react-toastify';
//import citiesData from '../images/cities.json';

// Hours 
const options = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
  { value: 21, label: '21' },
  { value: 22, label: '22' },
  { value: 23, label: '23' },
  { value: 24, label: '24' },
];
const Search = () => {
  dayjs.extend(isSameOrBefore);
  const storedSearch = getStoredSearch();
  const [filteredResults, setFilteredResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showDropOffSuggestions, setShowDropOffSuggestions] = useState(false);
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);
  const [showHoursSuggestions, setShowHoursSuggestions] = useState(false);
  const navigate = useNavigate();
  // const [tabIndex, setTabIndex] = useState(0);
  const [bookingtype, setBookingType] = useState('p_to_p');
  const [pickupPoint, setPickupPoint] = useState(storedSearch?.pickup ?? '');
  const [pickupType, setPickupType] = useState(storedSearch?.pickup_type ?? '');
  const [dropOffPoint, setDropOffPoint] = useState(storedSearch?.dropoff ?? '');
  const [dropoffType, setDropoffType] = useState(storedSearch?.dropoff_tpye ?? '');
  const [departureDate, setDepartureDate] = useState(storedSearch?.date === 'null' ? null : storedSearch?.date);
  const [pickupTimestamp, setPickupTimestamp] = useState(storedSearch?.pickup_timestamp ?? null);
  const [pickupTime, setPickupTime] = useState(null);
  const [returnDate, setReturnDate] = useState(storedSearch?.return_date === 'null' ? null : storedSearch?.return_date);
  const [returnTimestamp, setReturnTimestamp] = useState(storedSearch?.return_timestamp ?? null);
  const [returnPickUpTime, setReturnPickUpTime] = useState(null);
  const [passengers, setPassengers] = useState(1);
  const [bags, setBags] = useState(1);
  const [pickupcoordinates, setpickupCoordinates] = useState({ lat: null, lng: null });
  const [dropoffcoordinates, setdropoffCoordinates] = useState({ lat: null, lng: null });
  const [selectedduration, setSelectedDuration] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0)
    if (storedSearch) {
      setpickupCoordinates({
        lat: storedSearch.pickup_lat,
        lng: storedSearch.pickup_lng,
      });
      setdropoffCoordinates({
        lat: storedSearch.drop_lat,
        lng: storedSearch.drop_lng,
      });
      setBookingType(storedSearch?.booking_type);
      setPassengers(storedSearch?.pass);
      setBags(storedSearch?.bags);
      setBookingType(storedSearch?.booking_type ?? 'p_to_p');
      // setTabIndex(getTabIndex(storedSearch?.booking_type));
      setPickupTime(storedSearch?.time);
      setReturnPickUpTime(storedSearch?.return_pickup_time);
      setSelectedDuration(storedSearch?.timebased ? storedSearch.timebased : null);
    }
  }, []);
  const fetchPlaceDetailsPickup = (placeId) => {
    const map = new window.google.maps.Map(document.createElement('div'));
    const service = new window.google.maps.places.PlacesService(map);

    const request = {
      placeId: placeId,
      fields: ['name', 'formatted_address', 'geometry'],
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setpickupCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        });
      } else {
        console.error('Failed to get place details:', status);
      }
    });
  };
  const fetchPlaceDetailsDropoff = (placeId) => {
    const map = new window.google.maps.Map(document.createElement('div'));
    const service = new window.google.maps.places.PlacesService(map);

    const request = {
      placeId: placeId,
      fields: ['name', 'formatted_address', 'geometry'],
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setdropoffCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        });
      } else {
        console.error('Failed to get place details:', status);
      }
    });
  };

  const handlePickUpChange = async (e) => {
    const value = e.target.value;
    setPickupPoint(value);

    if (value && value.length > 2) {
      const url = `https://api.airporttransfer.com/api/booker_autocomplete?search=${value}`;
      try {
        const response = await axios.post(url);
        const fetchedResults = response.data.airports.map((item, index) => ({
          id: index,
          name: item.name,
          place: item.airport_place_id,
          type: 'airport',
          coordinates: item.coordinates
        }));
        const fetchedResultss = response.data.places.map((item, index) => ({
          id: index,
          name: item.description,
          place: item.place_id,
          type: 'place',
          coordinates: null
        }));
        const mergedResults = [...fetchedResults, ...fetchedResultss];
        setFilteredResults(mergedResults);
        setShowSuggestions(true)
      } catch (error) {
        console.error('Error fetching data:', error);
        setFilteredResults([]);
      }
    } else {
      setShowSuggestions(false);
      setFilteredResults([]);
    }
  };
  const handlePickUpClick = (option) => {
    if (option.place) {
      fetchPlaceDetailsPickup(option.place);
    } else {
      setpickupCoordinates({
        lat: option.coordinates.latitude,
        lng: option.coordinates.longitude
      });
    }
    setPickupPoint(option.name);
    setPickupType(option.type);
    setShowSuggestions(false);
  };
  const handleDropOffChange = async (e) => {
    const value = e.target.value;
    setDropOffPoint(value);

    if (value && value.length > 2) {
      const url = `https://api.airporttransfer.com/api/booker_autocomplete?search=${value}`;
      try {
        const response = await axios.post(url);
        const fetchedResults = response.data.airports.map((item, index) => ({
          id: index,
          name: item.name,
          place: item.airport_place_id,
          type: 'airport',
          coordinates: item.coordinates
        }));
        const fetchedResultss = response.data.places.map((item, index) => ({
          id: index,
          name: item.description,
          place: item.place_id,
          type: 'place',
          coordinates: null
        }));
        const mergedResults = [...fetchedResults, ...fetchedResultss];
        setFilteredResults(mergedResults);
        setShowDropOffSuggestions(true)
      } catch (error) {
        console.error('Error fetching data:', error);
        setFilteredResults([]);
      }
    } else {
      setShowDropOffSuggestions(false);
      setFilteredResults([]);
    }
  };


  const handleDropOffClick = (option) => {
    if (option.place) {
      fetchPlaceDetailsDropoff(option.place);
    } else {
      setdropoffCoordinates({
        lat: option.coordinates.latitude,
        lng: option.coordinates.longitude
      });
    }
    setDropOffPoint(option.name);
    setDropoffType(option.type);
    setShowDropOffSuggestions(false);
  };
  const handlePassengersChange = (increment) => {
    const total = Number(passengers) + Number(increment);
    if (total < 0) {
      setPassengers(0);
    } else {
      setPassengers(total);
    }

  };

  const handleBagsChange = (increment) => {
    const total = Number(bags) + Number(increment);
    if (total < 0) {
      setBags(0);
    } else {
      setBags(total);
    }
  };
  const handleHoursChange = (e) => {
    const value = e.target.value;
    setSelectedDuration(value);
    setShowHoursSuggestions(true);
  };
  const handleHoursClick = (option) => {
    setSelectedDuration(option.value);
    setShowHoursSuggestions(false);
  };
  const disablePastDates = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf('day');
  };
  const handleDateChange = (value, dateString) => {
    if (value) {
      const Timestamppp = value.valueOf();
      setPickupTimestamp(Timestamppp);
      const hours = String(value?.$H).padStart(2, '0');
      const minutes = String(value?.$m).padStart(2, '0');
      setPickupTime(`${hours}:${minutes}`);
      setDepartureDate(dateString ? dateString : null);
    } else {
      setPickupTime(null);
      setDepartureDate(null);
    }
  };
  const handleReturnDateChange = (value, dateString) => {
    if (value) {
    const returnTimestamppp = value.valueOf();
    setReturnTimestamp(returnTimestamppp);
    if(departureDate === null || departureDate === undefined){
      toast.error('Please select departure date first.');
      setReturnDate(null);
      return;
    }
    if (returnTimestamppp <= pickupTimestamp) {
      setReturnDate(null);
      toast.error('Return date must be greater than departure date');
      return;
    }
      const hours = String(value?.$H).padStart(2, '0');
      const minutes = String(value?.$m).padStart(2, '0');
      setReturnPickUpTime(`${hours}:${minutes}`);
      setReturnDate(dateString);
    } else {
      setReturnPickUpTime(null);
      setReturnDate(null);
    }
  };
  const handleBookingType = (value) => {
    setBookingType(value);
  };
  const validateInputs = () => {
    if (bookingtype === 'p_to_p') {
      return pickupPoint && dropOffPoint && departureDate && pickupTime && passengers && bags;
    } else if (bookingtype === 'intercity') {
      return pickupPoint && dropOffPoint && departureDate && pickupTime && passengers && bags;
    } else if (bookingtype === 'city_tour') {
      return pickupPoint && selectedduration && departureDate && pickupTime && passengers && bags;
    } else if (bookingtype === 'hourly') {
      return pickupPoint && selectedduration && departureDate && pickupTime && passengers && bags;
    }
  };
  const handleSubmit = () => {
    if (!validateInputs()) {
      toast.error("Please fill all the fields.");
      return;
    }
    localStorage.removeItem("booking_id");
    const params = new URLSearchParams();
    params.append("date", departureDate ?? null);
    params.append("return_date", returnDate ?? null);
    params.append("return_timestamp", returnTimestamp ?? null);
    params.append("pickup_timestamp", pickupTimestamp ?? null);
    params.append("return_pickup_time", returnPickUpTime ?? null);
    params.append("time", pickupTime ?? null);
    params.append("pickup", pickupPoint);
    params.append("pickup_lat", pickupcoordinates.lat);
    params.append("pickup_lng", pickupcoordinates.lng);
    params.append("drop_lat", dropoffcoordinates.lat);
    params.append("drop_lng", dropoffcoordinates.lng);
    params.append("dropoff", dropOffPoint);
    params.append("pass", passengers);
    params.append("bags", bags);
    params.append("pickup_type", pickupType);
    params.append("dropoff_type", dropoffType);
    if (bookingtype === 'hourly' || bookingtype === 'city_tour') {
      params.append("perhour", 'on');
      params.append("timebased", selectedduration);
    }
    params.append("booking_type", bookingtype);

    const paramsObject = {};
    for (const [key, value] of params) {
      paramsObject[key] = value;
    }
    // Step 2: Store Object in localStorage
    localStorage.setItem('search_param', JSON.stringify(paramsObject));
    navigate("/results?" + params.toString());
  };
  const handleSwitch = () => {
    setDropOffPoint(pickupPoint);
    setdropoffCoordinates({
        lat: pickupcoordinates.lat,
        lng: pickupcoordinates.lng
    });
    setPickupPoint(dropOffPoint);
    setpickupCoordinates({
        lat: dropoffcoordinates.lat,
        lng: dropoffcoordinates.lng
    });
}
  return (
    <>
      <Col md={'11'} className='col-11 text-center mx-auto tabs_wrap'>
        <Form>
          <ul className='tabUL list-unstyled d-flex col-lg-6 mx-auto justify-content-between mb-0 position-relative whiteBG py-3 px-3' style={{ borderRadius: '15px 15px 0px 0px' }}>
            <li className=''><Button onClick={() => handleBookingType('p_to_p')} className={`${bookingtype === 'p_to_p' ? 'selected' : ''} fgrf_btn h-100`} variant=''>Point To Point</Button></li>
            <li className=''><Button onClick={() => handleBookingType('intercity')} className={`${bookingtype === 'intercity' ? 'selected' : ''} fgrf_btn h-100`} variant=''>Intercity</Button></li>
            <li className=''><Button onClick={() => handleBookingType('hourly')} className={`${bookingtype === 'hourly' ? 'selected' : ''} fgrf_btn h-100`} variant=''>Hourly Ride</Button></li>
            <li className=''><Button onClick={() => handleBookingType('city_tour')} className={`${bookingtype === 'city_tour' ? 'selected' : ''} fgrf_btn h-100`} variant=''>City Tour</Button></li>
          </ul>
          {/*  */}
          <ul className='list-unstyled position-relative'>
            <li className='aye-hay-oye-hoye'>
              <Row className='align-items-center d-lg-flex d-block row-cols-3 gx-3'>
                <Col xxl={'6'} className='col-12 mb-3 mb-xxl-0'>
                  <Row className='text-start position-relative'>
                    <Col md={'6'} sm={'12'} className='mb-3 mb-md-0'>
                      <div className='text-start autosuggest w-100 justify-content-between align-items-center whiteBG px-3 py-1' style={{ borderRadius: '10px' }}>
                        <Form.Label className='mb-0'>PICKUP POINT</Form.Label>
                        <Form.Control ref={pickupRef} type="text" value={pickupPoint} onChange={handlePickUpChange} placeholder='Airport, address, place or hotel name...' />
                        {showSuggestions && (
                          <>
                          
                          <ul className="suggestions-list">
                            {filteredResults.map((option, index) => (
                              
                                <section className='suggestions-li' key={index} onClick={() => handlePickUpClick(option)} style={{ display: "flex", alignItems: 'center', padding: '6px', borderRadius: '6px', cursor:'pointer' }}>
                                  <div style={{ fill: '#626971', display: 'flex', marginRight: '.5rem' }}>
                                    <img
                                      src={option.type === 'airport' ? airoplaneIcon : placeIcon}
                                      alt={option.type}
                                      style={{ width: '25px', height: '25px' }}
                                    />
                                  </div>
                                  <div style={{ width: '100%' }}>
                                    <div style={{ display: "block" }}>
                                      <span>
                                        <span style={{ fontWeight: '700' }}>{option.name}</span>
                                      </span><br />
                                      <small>{option.hierarchy}</small>
                                    </div>
                                  </div>
                                </section>
                            ))}
                          </ul>
                          </>
                        )}
                      </div>

                    </Col>
                    {(bookingtype === 'intercity' || bookingtype === 'p_to_p') &&
                      <Button onClick={() => handleSwitch()} variant={''} className='exchangerbtn p-0 w-100 position-absolute start-0 end-0 mx-auto' style={{ maxWidth: '35px', zIndex: '1', minWidth: '35px' }}><img className='img-fluid' src={leftright_img} alt='leftright_img' /></Button>
                    }
                    {(bookingtype === 'hourly' || bookingtype === 'city_tour') &&
                      <Col md={'6'} sm={'12'}>
                        <div className='text-start autosuggest w-100 justify-content-between align-items-center whiteBG px-3 py-1' style={{ borderRadius: '10px' }}>
                          <Form.Label className='mb-0'>DURATION (in Hour)</Form.Label>
                          <Form.Control type="text" readOnly value={selectedduration} onFocus={handleHoursChange} onChange={handleHoursChange} className="border-0" />
                          {showHoursSuggestions && (<ul className="suggestions-list" style={{ top: '57px', left: '-1px', width: '269px' }}>
                            {options.map((option, index) => (
                              <section key={index} onClick={() => handleHoursClick(option)} style={{ display: "flex" }}>
                                <div style={{ width: '100%' }}>
                                  <div style={{ display: "block" }}>
                                    <span>
                                      <span style={{ fontWeight: '700' }}>{option.value}</span>
                                    </span>
                                  </div>
                                </div>
                              </section>
                            ))}
                          </ul>
                          )}
                        </div>
                      </Col>
                    }
                    {(bookingtype === 'intercity' || bookingtype === 'p_to_p') &&
                      <Col md={'6'} sm={'12'}>
                        <div className='text-start autosuggest w-100 justify-content-between align-items-center whiteBG px-3 py-1' style={{ borderRadius: '10px' }}>
                          <Form.Label className='mb-0'>DROP-OFF POINT</Form.Label>
                          <Form.Control ref={dropoffRef} type="text" value={dropOffPoint} onChange={handleDropOffChange} placeholder='Airport, address, place or hotel name...' />
                          {showDropOffSuggestions && (
                            <ul className="suggestions-list">
                              {filteredResults.map((option, index) => (
                                <section className='suggestions-li' key={index} onClick={() => handleDropOffClick(option)} style={{ display: "flex", alignItems: 'center', padding: '6px', borderRadius: '6px', cursor:'pointer' }}>
                                  <div style={{ fill: '#626971', display: 'flex', marginRight: '.5rem' }}>
                                    <img
                                      src={option.type === 'airport' ? airoplaneIcon : placeIcon}
                                      alt={option.type}
                                      style={{ width: '25px', height: '25px' }}
                                    />
                                  </div>
                                  <div style={{ width: '100%' }}>
                                    <div style={{ display: "block" }}>
                                      <span>
                                        <span style={{ fontWeight: '700' }}>{option.name}</span>
                                      </span><br />
                                      <small>{option.hierarchy}</small>
                                    </div>
                                  </div>
                                </section>
                              ))}
                            </ul>
                          )}
                        </div>
                      </Col>
                    }
                  </Row>
                </Col>
                <Col xxl={'5'} className='col-12'>
                  <Row className='text-start gx-3'>
                    <Col xl={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 4 : 6} lg={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 12 : 12} sm={'12'} className='mb-3 mb-xxl-0'>
                      <div className='w-100 whiteBG px-2' style={{ borderRadius: '10px', height: "56px" }}>
                        <Form.Label className='mb-0'>DEPARTURE DATE/TIME</Form.Label><br />
                        <DatePicker
                          showTime
                          disabledDate={disablePastDates}
                          defaultValue={departureDate ? dayjs(departureDate, 'DD-MM-YYYY HH:mm') : null}
                          onChange={handleDateChange}
                          onOk={handleDateChange}
                          format="DD-MM-YYYY HH:mm"
                          minuteStep={5}
                        />
                      </div>
                    </Col>
                    {(bookingtype === 'intercity' || bookingtype === 'p_to_p') &&
                      <Col xl={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 4 : 6} lg={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 6 : 6} sm={'12'} className='mb-3 mb-xxl-0'>
                        {returnDate ?
                          <div className='w-100 whiteBG px-2' style={{ borderRadius: '10px', height: "56px" }}>
                            <Form.Label className='mb-0'>RETURNS DATE/TIME</Form.Label>
                            <DatePicker
                              showTime
                              disabledDate={disablePastDates}
                              defaultValue={returnDate ? dayjs(returnDate, 'DD-MM-YYYY HH:mm') : null}
                              onChange={handleReturnDateChange}
                              onOk={handleReturnDateChange}
                              format="DD-MM-YYYY HH:mm"
                              minuteStep={5}
                            />
                          </div>
                          :
                          <div className='w-100 passengerDropdown' style={{ borderRadius: '10px', height: "56px" }}>
                            <Dropdown className="d-inline dropend dropenddropend">
                              <Dropdown.Toggle variant={''} style={{ borderRadius: '8px', maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%', }} className={'add-return1-btn whiteText'} id="dropdown-autoclose-true">Add Return</Dropdown.Toggle>
                              <Dropdown.Menu className='view p-2'>
                                <Form.Label className='mb-0'>RETURNS DATE/TIME</Form.Label><br />
                                <DatePicker
                                  defaultValue={returnDate ? dayjs(returnDate, 'DD-MM-YYYY HH:mm') : null}
                                  showTime
                                  disabledDate={disablePastDates}
                                  onChange={handleReturnDateChange}
                                  onOk={handleReturnDateChange}
                                  format="DD-MM-YYYY HH:mm"
                                  minuteStep={5}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        }
                      </Col>
                    }
                    <Col xl={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 4 : 6} lg={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 6 : 6} sm={'12'} className='mb-3 mb-xxl-0'>
                      <div className='w-100 whiteBG px-2 py-1 ' style={{ borderRadius: '10px', height: "56px" }}>
                        <Form.Label className='mb-0 d-block'>PASSENGERS / BAGS</Form.Label>
                        <Dropdown className="passengerDropdown">
                          <Dropdown.Toggle variant={''} className={'secondaryText border-0 ps-0 py-0 pe-0 d-block w-100 text-start'} style={{ fontSize: '14px', }} id="dropdown-autoclose-true">{passengers} Adult{passengers > 1 ? 's' : ''} / {bags} Bag{bags > 1 ? 's' : ''}</Dropdown.Toggle>
                          <Dropdown.Menu className='view p-3' style={{ width: '210px' }}>
                            <div className='d-flex  justify-content-between mb-2' style={{ gap: "10px" }}>
                              <span>Passengers</span>
                              <div>
                                <Button variant={''} type='button' className="color2BG" style={{ borderRadius: '100px', padding: '0', width: '25px', borderColor: '#727B92' }} onClick={() => handlePassengersChange(-1)}>-</Button>
                                <span className="mx-2">{passengers}</span>
                                <Button variant={''} type='button' className="mainBG blackText" style={{ borderRadius: '100px', borderColor: '#FAE069', padding: '0', width: '25px' }} onClick={() => handlePassengersChange(1)}>+</Button>
                              </div>
                            </div>
                            <div className='d-flex justify-content-between' style={{ gap: "10px" }}>
                              <span>Bags</span>
                              <div>
                                <Button variant={''} type='button' className="color2BG" style={{ borderRadius: '100px', padding: '0', width: '25px', borderColor: '#727B92' }} onClick={() => handleBagsChange(-1)}>-</Button>
                                <span className="mx-2">{bags}</span>
                                <Button variant={''} type='button' className="mainBG blackText" style={{ borderRadius: '100px', borderColor: '#FAE069', padding: '0', width: '25px' }} onClick={() => handleBagsChange(1)}>+</Button>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xxl={'1'}>
                  <div className='w-100 mb-3 mb-sm-0' style={{ borderRadius: '10px', height: "56px" }}>
                    <Button onClick={handleSubmit} style={{ borderRadius: '8px', maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%', }} className='d-flex btn add-return-btn align-items-center py-0'><img className='img-fluid mx-auto' src={searchIcon} alt='icon' /></Button>
                  </div>
                </Col>
              </Row>
            </li>
          </ul>
        </Form>
      </Col>
    </>
  );
};
export default Search;