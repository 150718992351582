import React, { useState, useEffect } from 'react';
import { Card, Col, Modal, Button, Row, NavItem } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import SlideButton from 'react-slide-button';
import { toast } from 'react-toastify';
import axios from 'axios';
import DriverMap from "../../components/DriverMap";
import config from '../../config';
import { getStoredUser } from '../../utils/localStorageUtils';

// import images
import profilePhoto from '../../images/profileDP.jpg';
import callIcon from '../../images/callIcon.svg';
import userIcon from '../../images/userIcon.svg';
import breifcaseIcon from '../../images/breifcaseIcon.svg';
import watchTimeIcon from '../../images/watchTimeIcon.svg';
import kmsIcon from '../../images/kmsIcon.svg';

import bultIcon from '../../images/bult.svg';
import mapIcon from '../../images/mapIcon.svg';
import arroweast from '../../images/arroweast.svg';
import bgdot from '../../images/bg-dots.svg';
import DriverFooter from '../../components/DriverFooter';

const MyJob = () => {
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [reset] = useState(0);
    const handlesubmit = () => {
        setModalIsOpen(false);
    }
    const [loading, setLoading] = useState(false);
    const handleShow = () => {
        setModalIsOpen(true);
    }
    const storedUser = getStoredUser();
    // const { currency, t } = props;
    const params = useParams();
    const { code } = params;

    const [modalOpen, setModelOpen] = useState(false);
    const [pinModalOpen, setPinModalOpen] = useState(false);
    const [location, setLocation] = useState({});
    const [payloadData, setPayloadData] = useState({});
    const [clicked, setClicked] = useState(false);
    const [shouldReturn, setShouldReturn] = useState(false);
    const [directions, setDirections] = useState({});
    const [driveStart, setDriveStart] = useState(
        localStorage.getItem("driveStart")
            ? localStorage.getItem("driveStart")
            : false
    );
    const [journeyStartData, setJourneyStartData] = useState({});
    const [journeyId, setJourneyId] = useState(
        localStorage.getItem("journeyId") ? localStorage.getItem("journeyId") : ""
    );
    const [destination, setDestination] = useState(
        localStorage.getItem("destination")
            ? localStorage.getItem("destination")
            : ""
    );
    const [driverExecuteData, setDriverExecuteData] = useState({});
    const [driverArriving, setDriverArriving] = useState(
        localStorage.getItem("driverArriving")
            ? localStorage.getItem("driverArriving")
            : false
    );

    const getDriverExecute = async () => {
        const response = await axios.get(`${config.getJobDetailUrl(code)}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
                "Authorization": `Bearer ${storedUser?.token.access_token}`
            }
        });
        if (response?.status === 200) {
            setDriverExecuteData(response?.data?.data);
            setPayloadData({
                ...payloadData,
                booking_id: response?.data?.data.bookingId,
            });
            localStorage.setItem("driveInfo", JSON.stringify(response?.data?.data));
        }
    };

    useEffect(() => {
        getDriverExecute();
    }, [code]);
  //API for Move step 4 customer no show
  const configCustomerNoShow = {
    method: "POST",
    url: `/api/v1/driverservices/customer-no-show/${journeyId}/`,
    data: { arrivalPoint: `${location.lat}, ${location.long}` },
  };
  const submitCustomerNoShow = async () => {
    const customerNoShowResp = await axios(configCustomerNoShow).catch(
      (e) => {}
    );

    if (customerNoShowResp?.status === 200) {
      navigate("/account");
      setShouldReturn(false);
      setClicked(false);
      setDriverArriving(false);
      localStorage.setItem("driverArriving", "false");
      toast.success(customerNoShowResp?.data?.message || "Customer No Show!");
    }
  };

  //API for Move step 5 customer no show
  const configJourneyEnd = {
    method: "POST",
    url: `/api/v1/driverservices/journey-end/${journeyId}/`,
    data: { destinationLocation: `${location.lat}, ${location.long}` },
  };
  const submitJourneyEnd = async () => {
    try {
      const journeyEndResp = await axios(configJourneyEnd);
      // console.log("I am at destination api resp...", journeyEndResp);
      if (journeyEndResp?.status === 200) {
        // Handle success
        localStorage.removeItem("driveInfo");
        localStorage.removeItem("journeyId");
        localStorage.removeItem("driveStart");
        localStorage.removeItem("driverArriving");
        localStorage.removeItem("destination");
        navigate("/account");
        toast.success(journeyEndResp?.data?.message || "Journey End!");
      } else {
        // Handle error
        toast.error("Some thing wrong with Journey End!");
      }
    } catch (error) {
      toast.error("You are too far from the destination location");
    }
  };

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setLocation({ lat: latitude, long: longitude });
            },
            (error) => {
              console.error("Error getting location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      }, []);
    return (
        <>
            <div style={{ backgroundColor: '#F3F3F3' }} className='pb-4 position-relative'>
                <section className='myJob secondaryBG position-relative'>
                    <Col xxl={'9'} xl={'9'} lg={'10'} md={'11'} className='col-11 mx-auto'>
                        <h4 className="mainText fw-bold" style={{ fontSize: '22px', }}>My Job</h4>
                    </Col>
                </section>
                <section className='myJob py-0' style={{ marginBottom: '78px' }}>
                    <Col xxl={'9'} xl={'9'} lg={'10'} md={'11'} className='col-11 mx-auto'>
                        <Card className='whiteBG' style={{ borderColor: '#fff', borderRadius: '30px', boxShadow: '0px 0px 5px 0px #DDDDDD', }}>
                            <Card.Body>
                                <div className='d-flex align-items-center mb-lg-5 mb-4'>
                                    <Link to={'/'} className='d-flex align-items-center'>
                                        <div className='profilePicture me-3'><img className='img-fluid' src={profilePhoto} alt='image' /></div>
                                        <div className=''>
                                            <h4 className='mb-2 title'>John Doe</h4>
                                            <p className='mb-0' style={{ fontSize: '14px', lineHeight: '16px', fontWeight: '300' }}>Standard Sedan - Dz...</p>
                                        </div>
                                    </Link>
                                    <div className='ms-auto text-end'>
                                        <img className='img-fluid' src={callIcon} alt='img' />
                                        <h5 className='title'>LW 4763 AB</h5>
                                    </div>
                                </div>
                                {/*  */}
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <Link to="#" className=''><img className='img-fluid me-2' src={userIcon} alt='icon' /> 3</Link>
                                    <Link to="#" className=''><img className='img-fluid me-2' src={breifcaseIcon} alt='icon' /> 2</Link>
                                    <Link to="#" className=''><img className='img-fluid me-2' src={watchTimeIcon} alt='icon' /> 29 M</Link>
                                    <Link to="#" className=''><img className='img-fluid me-2' src={kmsIcon} alt='icon' /> 17.79 Kms</Link>
                                </div>
                                {/*  */}
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='mb-0'>Date & Time</p>
                                    <p className='mb-0 secondaryText'>Jan 10 2024 | 10:35 AM</p>
                                </div>
                                <hr />
                                <div className='mx-auto col-11 py-4 designLine'>
                                    <div className='circleIcon position-relative'>
                                        <NavItem className='d-flex align-items-center mb-4 justify-content-center position-relative' style={{ zIndex: '1', }}>
                                            <div className='col-12 d-flex align-items-center'>
                                                <div className='me-3 w-100 h-100 d-flex align-items-center justify-content-center mb-lg-0 mb-3' style={{ borderRadius: '100px', background: '#C8D5E8', minHeight: '60px', maxWidth: '60px', minWidth: '60px', maxHeight: '60px', }}><img className='img-fluid' src={bultIcon} alt='image' /></div>
                                                <div className=''>
                                                    <h5 className='mb-0' style={{ fontSize: '18px', fontWeight: '600', }}>Pick Up Location</h5>
                                                    <p className='mb-0' style={{ fontSize: '14px', }}>Chennai International Airport (MAA, Madras, India).</p>
                                                </div>
                                            </div>
                                        </NavItem>
                                        {/*  */}
                                        <NavItem className='d-flex align-items-center mb-4 justify-content-center position-relative' style={{ zIndex: '1', }}>
                                            <div className='col-12 d-flex align-items-center'>
                                                <div className='me-3 w-100 h-100 d-flex align-items-between justify-content-center mb-lg-0 mb-3' style={{ borderRadius: '100px', background: '#C8D5E8', minHeight: '60px', maxWidth: '60px', minWidth: '60px', maxHeight: '60px', }}><img className='img-fluid' src={mapIcon} alt='image' /></div>
                                                <div className=''>
                                                    <h5 className='mb-0' style={{ fontSize: '18px', fontWeight: '600', }}>Drop off  Location</h5>
                                                    <p className='mb-0' style={{ fontSize: '14px', }}>Chennaim,Tamil Nadu, India.</p>
                                                </div>
                                            </div>
                                        </NavItem>
                                    </div>
                                </div>                  
                                              {/* Map Area */}
                                <div className="py-1 h-80 w-full mb-3 overflow-hidden" style={{ borderRadius: '45px',height: '250px'}}>
                                    {driverExecuteData?.pickupLatLong ? (
                                        <DriverMap
                                            defaultLocation={{
                                                lat: Number(driverExecuteData?.pickupLatLong?.lat),
                                                lng: Number(driverExecuteData?.pickupLatLong?.long),
                                            }}
                                            destination={{
                                                lat: Number(driverExecuteData?.destinationLatLong?.lat),
                                                lng: Number(driverExecuteData?.destinationLatLong?.long),
                                            }}
                                            origin={{
                                                lat: Number(driverExecuteData?.pickupLatLong?.lat),
                                                lng: Number(driverExecuteData?.pickupLatLong?.long),
                                            }}
                                        />
                                    ) : null}
                                </div>
                                {/*  */}
                                <SlideButton
                                    mainText="I Am at Destination"
                                    overlayText=""
                                    overlayClassList="custom-width"
                                    classList="mainText"
                                    caretClassList="custom-caret-list"
                                    reset={reset}
                                    caret={<p className='mb-0' style={{ color: "white" }}><img className='img-fluid' style={{width:'25px',}} src={arroweast} alt='icon'/></p>}
                                    customCaretWidth={55}
                                    onSlideDone={() => {
                                        toast("Done!", { type: "info" });
                                        setModalIsOpen(true);
                                       
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </section>
                {/*  */}
                <DriverFooter />
            </div>
            <Modal show={modalIsOpen} className="finish_trip_modal" centered>
                <Modal.Body className="p-0 mt-3 text-center">
                    <img src={bgdot} className='img-fluid' alt='sdsd' />
                    <br />
                    <p style={{fontSize:'18px', fontWeight: '700', color: '#162238'}}>See you on the next trip :)</p>
                    <div className=''>
                        <Button onClick={handlesubmit} className={'w-100 allView-btn d-inline-block'}>Done</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MyJob;