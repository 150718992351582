import React, {useEffect} from 'react';
import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { isAuthenticated } from './utils/auth';
import { ToastContainer } from 'react-toastify';
import CookieConsent from "react-cookie-consent";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '../node_modules/react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import DriverHeader from './components/DriverHeader';
import ScrollToTop from './components/ScrollToTop.jsx';
import SignIn from './pages/SignIn';
import MyJob from './pages/MyJob';
import Logout from './pages/Logout';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register.jsx';
import TourDetail from './pages/TourDetail';
import TestPage from './pages/TestPage';
import ResultsPage from './pages/ResultsPage';
import Profile from './pages/Profile';
import DProfile from './pages/Driver/DProfile.jsx';
import DriverProfile from './pages/DriverProfile';
import UpdatePassword from './pages/UpdatePassword';
import CitySeo from './pages/CitySeo';
import BookingConfirmation from './pages/BookingConfirmation';
import BookingConfirmationInvoice from './pages/BookingConfirmationInvoice';
import BookingVoucher from './pages/BookingVoucher';
import BookingVoucherPublic from './pages/BookingVoucherPublic.jsx';
import BookingVoucherP from './pages/BookingVoucherP.jsx';
import BookingVoucherPInvoice from './pages/BookingVoucherPInvoice.jsx';
import ArrivedAtPickup from './pages/Driver/ArrivedAtPickup.jsx';
import CustomerOnBoardOrNot from './pages/Driver/CustomerOnBoardOrNot.jsx';
import AtDestination from './pages/Driver/AtDestination.jsx';
import AddPin from './pages/Driver/AddPin.jsx';
// Footer Page Importing
import HowItWork from './pages/HowItWork.jsx';
import Newsletter from './pages/Newsletter.jsx';
import Careers from './pages/Careers.jsx';
import Investors from './pages/Investors.jsx';
import Blogs from './pages/Blogs.jsx';
import BlogDetail from './pages/BlogDetail.jsx';
import Destinations from './pages/Destinations.jsx';
import AirportTransfer from './pages/AirportTransfer.jsx';
import LimousineService from './pages/LimousineService.jsx';
import ParivatTaxi from './pages/ParivatTaxi.jsx';
import ChauffeurService from './pages/ChauffeurService.jsx';
import CityTour from './pages/CityTour.jsx';
import BusinessSolutions from './pages/BusinessSolutions.jsx';
import TravelAgencies from './pages/TravelAgencies.jsx';
import AffiliateWebmasters from './pages/AffiliateWebmasters.jsx';
import DriveWithUs from './pages/DriveWithUs.jsx';
import BecomeaPartner from './pages/BecomeaPartner.jsx';
import RegisterGuide from './pages/RegisterGuide.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import TermsConditions from './pages/TermsConditions.jsx';
import CookiePolicy from './pages/CookiePolicy.jsx';
import HelpCenter from './pages/HelpCenter.jsx';
import GetSupport from './pages/GetSupport.jsx';

import Footer from './components/Footer';
import DriverFooter from './components/DriverFooter';
import Home from './pages/Home';

import '../node_modules/jquery/dist/jquery.js';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import './js/main.js';


import "../node_modules/bootstrap/dist/css/bootstrap.css";
import 'react-tabs/style/react-tabs.css';
import './App.css';
const stripePromise = loadStripe("pk_live_51JxEoxEtkeGM0TzZr2P0NhCKBQRTbHDAkpEyhvc1VnDg26Z0K6Xdgu0v8HIl2PLOymbNBOzbpQKCgwiHEZGhnzKY00CE1frpod");
const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/signin" />;
};
// Layout component with Header and Footer
const DefaultLayout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);
const DriverDefaultLayout = ({ children }) => (
  <>
    <DriverHeader />
    {children}
  </>
);

// Component for routes that do not need Header and Footer
const PlainLayout = ({ children }) => <>{children}</>;
function checkSessionValidity() { 
  let prevSessionRaw = localStorage.getItem("last_session");

  if (!prevSessionRaw) return;

  const prevSession = new Date(prevSessionRaw);

  const currentDate = new Date();

  const diff = currentDate.getTime() - prevSession.getTime();

  const msInDay = 24 * 60 * 60 * 1000;

  if (diff > msInDay) {
    localStorage.clear();
    window.location.reload();
  }
};

const App = () => {
  useEffect(() => {
    checkSessionValidity();

    const interval = setInterval(() => {
      checkSessionValidity();
    }, 10 * 60 * 1000); // Check every 10 minutes

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
      />
      <Routes>
        {/* Routes with DefaultLayout (Header and Footer) */}
        <Route path="/tour-detials" element={<DefaultLayout><TourDetail /></DefaultLayout>} />
        <Route path="/register" element={<DefaultLayout><Register /></DefaultLayout>} />
        <Route path="/signin" element={<DefaultLayout><SignIn /></DefaultLayout>} />
        <Route path="/logout" element={<DefaultLayout><Logout /></DefaultLayout>} />
        <Route path="/forgot-password" element={<DefaultLayout><ForgotPassword /></DefaultLayout>} />
        <Route path="/reset-password" element={<DefaultLayout><ResetPassword /></DefaultLayout>} />
        <Route path="/results" element={<DefaultLayout><Elements stripe={stripePromise}><ResultsPage /></Elements></DefaultLayout>} />
        <Route path="/profile" element={<DefaultLayout><PrivateRoute><Profile /></PrivateRoute></DefaultLayout>} />
        <Route path="/d-profile" element={<DriverDefaultLayout><PrivateRoute><DProfile /></PrivateRoute></DriverDefaultLayout>} />
        <Route path="/my-job/:code" element={<DriverDefaultLayout><PrivateRoute><MyJob /></PrivateRoute></DriverDefaultLayout>} />
        <Route path="/arrived-at-pickup/:code" element={<DriverDefaultLayout><PrivateRoute><ArrivedAtPickup /></PrivateRoute></DriverDefaultLayout>} />
        <Route path="/customer-on-board-or-not/:code" element={<DriverDefaultLayout><PrivateRoute><CustomerOnBoardOrNot /></PrivateRoute></DriverDefaultLayout>} />
        <Route path="/at-destination/:code" element={<DriverDefaultLayout><PrivateRoute><AtDestination /></PrivateRoute></DriverDefaultLayout>} />
        <Route path="/add-pin" element={<PrivateRoute><AddPin /></PrivateRoute>} />
        <Route path="/driver-profile" element={<DriverDefaultLayout><PrivateRoute><DriverProfile /></PrivateRoute></DriverDefaultLayout>} />
        <Route path="/update-password" element={<DefaultLayout><PrivateRoute><UpdatePassword /></PrivateRoute></DefaultLayout>} />
        <Route path="/popular_locations/:slug" element={<DefaultLayout><CitySeo /></DefaultLayout>} />
        <Route path="/booking-confirmation" element={<DefaultLayout><BookingConfirmation /></DefaultLayout>} />
        <Route path="/booking-confirmation-invoice" element={<DefaultLayout><BookingConfirmationInvoice /></DefaultLayout>} />
        {/* Route without Header and Footer */}
        <Route path="/booking-voucher-public" element={<PlainLayout><BookingVoucherP /></PlainLayout>} />
        <Route path="/booking-voucher-public-invoice" element={<PlainLayout><BookingVoucherPInvoice /></PlainLayout>} />
        <Route path="/booking-voucher" element={<PlainLayout><BookingVoucher /></PlainLayout>} />
        <Route path="/booking/:id" element={<PlainLayout><BookingVoucherPublic /></PlainLayout>} />
        <Route path="/" element={<DefaultLayout><Home /></DefaultLayout>} />
        {/* Footer Pages Routing */}
        <Route path="/how-it-works" element={<DefaultLayout><HowItWork /></DefaultLayout>} />
        <Route path="/newsletter" element={<DefaultLayout><Newsletter /></DefaultLayout>} />
        <Route path="/careers" element={<DefaultLayout><Careers /></DefaultLayout>} />
        <Route path="/investors" element={<DefaultLayout><Investors /></DefaultLayout>} />
        <Route path="/blogs" element={<DefaultLayout><Blogs /></DefaultLayout>} />
        <Route path="/blog-detail/:slug" element={<DefaultLayout><BlogDetail /></DefaultLayout>} />
        <Route path="/destinations" element={<DefaultLayout><Destinations /></DefaultLayout>} />
        <Route path="/airport-transfer" element={<DefaultLayout><AirportTransfer /></DefaultLayout>} />
        <Route path="/limousine-service" element={<DefaultLayout><LimousineService /></DefaultLayout>} />
        <Route path="/private-taxi" element={<DefaultLayout><ParivatTaxi /></DefaultLayout>} />
        <Route path="/chauffeur-service" element={<DefaultLayout><ChauffeurService /></DefaultLayout>} />
        <Route path="/city-tour" element={<DefaultLayout><CityTour /></DefaultLayout>} />
        <Route path="/business-solutions" element={<DefaultLayout><BusinessSolutions /></DefaultLayout>} />
        <Route path="/travel-agencies" element={<DefaultLayout><TravelAgencies /></DefaultLayout>} />
        <Route path="/affiliate-webmasters" element={<DefaultLayout><AffiliateWebmasters /></DefaultLayout>} />
        <Route path="/drive-with-us" element={<DefaultLayout><DriveWithUs /></DefaultLayout>} />
        <Route path="/become-a-partner" element={<DefaultLayout><BecomeaPartner /></DefaultLayout>} />
        <Route path="/register-guide" element={<DefaultLayout><RegisterGuide /></DefaultLayout>} />
        <Route path="/privacy-policy" element={<DefaultLayout><PrivacyPolicy /></DefaultLayout>} />
        <Route path="/terms-conditions" element={<DefaultLayout><TermsConditions /></DefaultLayout>} />
        <Route path="/cookie-policy" element={<DefaultLayout><CookiePolicy /></DefaultLayout>} />
        <Route path="/help-center" element={<DefaultLayout><HelpCenter /></DefaultLayout>} />
        <Route path="/get-support" element={<DefaultLayout><GetSupport /></DefaultLayout>} />
        <Route path="/test-page" element={<DefaultLayout><TestPage /></DefaultLayout>} />
        <Route path="*" element={<DefaultLayout><Home /></DefaultLayout>} />
      </Routes>
      <CookieConsent
          location="bottom"
          buttonText="Got it!"
          cookieName="yourCookieName"
          style={{ background: "#222", color: "#fff" }}
          buttonStyle={{ background: "#4e503b", color: "#fff", fontSize: "13px", zIndex: '9999999999999' }}
          expires={150}
        >
          This website uses cookies to ensure you get the best experience on our
          website.
        </CookieConsent>
    </Router>
  );
}

export default App;
